import { CommonModule, registerLocaleData } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import localeFr from '@angular/common/locales/fr';
import { TokenInterceptorProvider } from './interceptors/token-interceptor';
import { InfoDialogComponent } from './components/dialogs/utilities/info-dialog/info-dialog.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';



import { AngularEditorModule } from '@kolkov/angular-editor';

import { GoogleAnalyticsModule, GA_TOKEN } from 'angular-ga';
import { LoadingComponent } from './components/dialogs/utilities/loading-component/loading-component.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SaveComponent } from './pages/testimonials/testimonials-components/save/save.component';
import { ListComponent } from './pages/testimonials/testimonials-components/list/list.component';

registerLocaleData(localeFr, 'fr-FR');
@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
    InfoDialogComponent,
    SaveComponent,
    ListComponent,
 
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatDialogModule,
    HttpClientModule,
    FontAwesomeModule,
    FormsModule,
    AngularEditorModule,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   Register the ServiceWorker as soon as the application is stable
    //   or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),
    
    GoogleAnalyticsModule.forRoot(),
 
  ],

  providers: [ 
    { provide: GA_TOKEN, useValue: 'G-86JSZ0LV15' }
    ,TokenInterceptorProvider,
     { provide: MatDialogRef, useValue: {}}, 
     { provide: MAT_DIALOG_DATA, useValue: {} }, ],
  bootstrap: [AppComponent]
})
export class AppModule {

}

export function httpTranslateLoader(http:HttpClient){
  return new TranslateHttpLoader(http)
}
