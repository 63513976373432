import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Testimonial } from 'src/app/entities/tesimonial';
import { AppConfigService } from 'src/app/services/app-config.service';
import { TestimonialService } from 'src/app/services/testimonials.service';

@Component({
  selector: 'app-save',
  templateUrl: './save.component.html',
  styleUrls: ['./save.component.scss']
})
export class SaveComponent implements OnInit {

  testimonial:Testimonial=new Testimonial()
  userErrors:any;

  agents!:string | null;
  constructor(
    public appConfig: AppConfigService,
    private dialog: MatDialog,
    private testimonialService: TestimonialService
  ) { }

  ngOnInit(): void {
    const agentId=this.appConfig.getItemFromlocalStorage("accountId")
    this.agents=agentId
  }

 async  validateBeforeSendData(){

  const canSave= await this.appConfig.validateOrReject(this.testimonial)

    if(canSave==undefined || canSave==true){
     this.save() 
    } 
    else{
      this.userErrors= await this.appConfig.validateOrReject(this.testimonial)
      console.log('error', this.userErrors)
    }
  }

  async save(){
    this.testimonial.agents=this.agents;
    this.testimonialService.save(this.testimonial, "/app/testimonials/list-testimonials")
    this.clear();
  }

  clear(){
    this.testimonial=new Testimonial()
  }


}
