import { IsNotEmpty } from "class-validator";
import { GenericValidatorMessages } from "../utilities/genertic-validator-message";

export class Testimonial{
    @IsNotEmpty(GenericValidatorMessages.genericEmptyMessage("Le titre"))
    titre!:string;

    description!:string;

    @IsNotEmpty(GenericValidatorMessages.genericEmptyMessage("L'url"))
    url!:string;

    agents!:string | null;
}