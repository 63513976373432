import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faCheckCircle, faClipboardList, faWarning, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import {Clipboard} from '@angular/cdk/clipboard';
@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit {

  @ViewChild('code') code !: ElementRef
  message!: string;
  icon:IconDefinition= faWarning;
  custom:boolean=false;
  autoClose:boolean=true;

  faCopy=faClipboardList;

  faWarning=faWarning;
  faCheck=faCheckCircle;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private clipboard: Clipboard,
    private dialogRef: MatDialogRef<InfoDialogComponent>
  ) {
    if(data)
    this.message = data.message;
    this.icon=data.icon;
    this.custom=data.custom
    this.autoClose=data.close

  }
  ngOnInit(): void {
    if(this.autoClose){
      this.automaticClose()
    }
     
  }

  automaticClose(){
    setTimeout(()=> {
      this.close()
    },3000)
  }

  close(){
    this.dialogRef.close('yes')
  }

  copyCode(){

    const text=this.code.nativeElement.innerHTML

    if(window.isSecureContext){
      navigator.clipboard.writeText(text).then(() => {
        alert(`${text} a été copié sur le presse-papiers`)
    })

    }
    else{
      this.clipboard.copy(text);
      alert(`${text} a été copié sur le presse-papiers`)
    }

  }

}
