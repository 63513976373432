
<div class="container-fluid p-4 " style="border-radius:20px;">
    <div class="row">
        <div class="col-12 d-flex flex-column align-items-center justify-content-center">
             <!-- <div class="lds-ripple"><div></div><div></div></div> -->

             <div class="loadingio-spinner-double-ring-3wbfv4ilhxv"><div class="ldio-pqmmg4sfm9f">
              <div></div>
              <div></div>
              <div><div></div></div>
              <div><div></div></div>
              </div></div>
             <!-- <div class="fa-spin-move">
              <fa-icon  class="ms-3 text-danger fa-3x fa-spin-move" [icon]="faTruck"  ></fa-icon>
            </div> -->
            <p style="font-weight:600 !important;"> Veuillez patienter pendant que nous chargeons vos données ... </p>
        </div>
    </div>

</div>


