import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { catchError, map, Observable } from "rxjs";
import { AppFeeback } from "../enums/app-feedback.enum";
import { IRepository } from "../interfaces/repository.interface";

export abstract class Repository<T> implements IRepository<T>{

  main_headers:any = {}
  httpOptions:any={
    headers: {'Content-Type':'application/json'},
    observe:'response',
    responseType: 'json'
  }

    constructor(
        protected readonly _http: HttpClient,
        protected readonly _base: string,
        protected readonly _router: Router,
        protected readonly _dialog: MatDialog
    ) {}



    find$(uri: string | undefined): Observable<T[] | T | any> {
        return this._http.get<T[] | T>(this._base + `/${uri}`, this.httpOptions)
        .pipe(
            map((res: any) => {

              sessionStorage.setItem('token', res.headers.get('token'))
              return res.body;
            }),
            catchError(error => {
                console.log('update ', error)

                const errorStatut = error.status;

                switch(errorStatut){
                    case 404:
                        throw AppFeeback.NOT_FOUND_ERROR
                    default:
                         throw error.status
                }

            })
        )
    }

    save$(data: T, uri: string): Observable<T> {
        const URI=uri!=="" ? `/${uri}` : `${uri}`
        return this._http.post<T>(this._base + `${uri}`, data,this.httpOptions)
        .pipe(
            map((res: any) => {
              sessionStorage.setItem('token', res.headers.get('token'))
                return res.body
            }),
            catchError(error => {
                console.log('update ', error)

                const errorStatut = error.status;

                switch(errorStatut){
                    case 404:
                        throw AppFeeback.NOT_FOUND_ERROR
                    default:
                         throw error.status
                }

            })
        )
    }

    update$(data: T, uri: string | undefined): Observable<T> {
        return this._http.put<T>(this._base + `/${uri}`, data,this.httpOptions)
        .pipe(
            map((res: any) => {
                sessionStorage.setItem('token', res.headers.get('token'))
                return res.body
            }),
            catchError(error => {
                console.log('update ', error)

                const errorStatut = error.status;

                switch(errorStatut){
                    case 404:
                        throw AppFeeback.NOT_FOUND_ERROR
                    default:
                         throw error.status
                }

            })
        )

    }

    delete$(uri: string | undefined): Observable<T> {
        return this._http.delete<T>(this._base + `/${uri}`,this.httpOptions)
        .pipe(
            map((res: any) => {
                sessionStorage.setItem('token', res.headers.get('token'))
                return res.body
            }),
            catchError(error => {
                console.log('update ', error)

                const errorStatut = error.status;

                switch(errorStatut){
                    case 404:
                        throw AppFeeback.NOT_FOUND_ERROR
                    default:
                         throw error.status
                }

            })
        )
    }

    patch$(data: T, uri: string | undefined): Observable<T> {
        return this._http.patch<T>(this._base + `/${uri}`, data, this.httpOptions)
        .pipe(
            map((res: any) => {
                sessionStorage.setItem('token', res.headers.get('token'))
                return res.body
            }),
            catchError(error => {
                console.log('update ', error)

                const errorStatut = error.status;

                switch(errorStatut){
                    case 404:
                        throw AppFeeback.NOT_FOUND_ERROR
                    default:
                         throw error.status
                }

            })
        )
    }
}
