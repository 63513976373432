import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faSpinner, faTruck } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-loading-component',
  templateUrl: './loading-component.component.html',
  styleUrls: ['./loading-component.component.scss']
})
export class LoadingComponent implements OnInit {

  faSpinner=faSpinner;
  faTruck=faTruck

  constructor(
    private dialogRef: MatDialogRef<LoadingComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    if (data.stop) {
      this.dialogRef.close()
    }
  }


  ngOnInit(): void {
  }

}
