export class GenericValidatorMessages {
    public static genericStringMessage(title?: string) {
      return { message: `${title} doit être une chaine de caractère !` };
    }
    public static genericNumberMessage(title?: string) {
      return { message: `${title} doit être un nombre !` };
    }
  
   public static genericEmptyMessage(title?: string) {
      return { message: `${title} ne doit pas être vide !` };
    }
  }