import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppFeeback } from 'src/app/enums/app-feedback.enum';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  message: string = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {
    if(data)
      this.message = data.message
    else{
      this.message = "Sure to make this operation ?"
    }
  }

  ngOnInit(): void {
  }

  confirm(){
    this.dialogRef.close(AppFeeback.YES)
  }

  close(){
    this.dialogRef.close()
  }
}
