<div class="shadow-custom bg-white p-4" style="border-radius: 17px;" >

    <div class="d-flex align-items-center">
        <div class="col-md-3 col-3 d-none d-md-block">
          
            <i class=" {{icon==faWarning ? 'text-warning bi bi-exclamation-triangle-fill':'text-success bi bi-check-circle-fill'}} " style="font-size: 100px !important;"></i>

        </div>
        <div class="col-md-9 col-9 ">
            <h2 >Notification</h2>
            <div class="mb-4" style="font-size: 14px !important;" *ngIf="!custom">
                <span> {{ message }} </span>
            </div>
            <div class="" *ngIf="custom">
              <p>Nouveau mot de passe :  <span style="font-weight:800; cursor:pointer;" id="code" #code class="me-3"  > {{ message }}  </span> <sup>
                <img (click)="close()" (click)="copyCode()" title="Click to copy on the clipboard" style="cursor:pointer;" src="/assets/clipboard2.png" width="30"/>
                 </sup>
             </p>
            </div>

            <div class="mt-3 row offset-10 col-2" *ngIf="!autoClose">
              <button class="btn bg-secondary text-white " (click)="close()">
                  OK
              </button>
          </div>

        </div>
    </div>

</div>
