<div
class="container mt-3 p-0"
>
  <div class="row ">
  <div class="col-12">
      <form
      style="border-radius: 17px;"
      class="shadow-custom bg-white px-4 py-4"
      >

    <div>
      <label for="titre">Titre<span class="text-danger">*</span></label>
      <input type="text"  name="titre" [(ngModel)]="testimonial.titre"  class="form-control" >
      <p class="my-2 message-error">
          {{ userErrors?.titre ? userErrors.titre : "" }}
      </p>
    </div>

    <div>
        <label for="description">Description</label>
        <textarea type="text" name="description" rows="4" [(ngModel)]="testimonial.description"  class="form-control" >
        </textarea>
        <p class="my-2 message-error">
            {{ userErrors?.description ? userErrors.description : "" }}
        </p>
    </div>

    <div>
        <label for="url">Url<span class="text-danger">*</span></label>
        <input type="text"  name="url" [(ngModel)]="testimonial.url"  class="form-control" >
        <p class="my-2 message-error">
            {{ userErrors?.url ? userErrors.url : "" }}
        </p>
      </div>

    <div class="col-md-12 mt-3">
        <button type="submit" class="btn btn-success-custom "  (click)="validateBeforeSendData()"> Ajouter </button>
        <button type="reset" class="btn bg-secondary  ms-3 text-white"> Réinitialiser </button>
    </div>

  </form>

  </div>

  </div>
</div>
