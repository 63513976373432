import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService } from "../services/app-config.service";

@Injectable({
  providedIn: 'root'
})

export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private appConfig: AppConfigService
  ){
 
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    if(req.url.includes('login') || req.url.includes('ipinfo.io/json')){
      return next.handle(req);
    }

    else if(req.url.includes('from')){
        localStorage.setItem('from',req.url)
    }
     
    if(req.url.includes('finance/gf/list')){
      
      const newRequest = req.clone({
        setHeaders: {
          'Authorization': `Bearer ${this.appConfig.getItemFromlocalStorage("token")}`,
          'Accept-Language': "fr-FR",
       
        }
      });
      return next.handle(newRequest);
    }

  
    const newRequest = req.clone({
      setHeaders: {
        'Authorization': `Bearer ${this.appConfig.getItemFromlocalStorage("token")}`
      }
    });
    return next.handle(newRequest);
  }

  async getIpInfos(){
   
    var myHeaders = new Headers();
  
    myHeaders.append("Content-Type", 'application/json');
    const uri='https://ipinfo.io/json';
   
      var requestOptions:any = {
        method: 'GET',
        headers:myHeaders,
      
      };
      
     try{
        const request= await fetch(`${uri}`, requestOptions)
        console.log(' personnal info ', await request.json())
       
     }
     catch(err){
       // this.appConfig.onStopWaiting()
      console.log('error send file', err)
     }
      
    
  }
}

export const TokenInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor,
  multi: true
}
