import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { AppFeeback } from "../enums/app-feedback.enum";
import { Repository } from "../repository/repository";
import { AppUtilitie } from "../utilities/app-utilitie";
import { AppConfigService } from "./app-config.service";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn:"root"
})
export class TestimonialService extends Repository <any>{

    testimonial$=new Subject<Array<any>>()
  
    
    constructor(
        protected override _http: HttpClient,
        protected override _router: Router,
        protected override _dialog: MatDialog,
        private appConfig: AppConfigService,
        private dialog: MatDialog,
    ) {
        super(_http, `${environment.baseUrl}/stories`, _router, _dialog);
    }
  
    save(data:any, redirectTo:string){
      this.appConfig.onStartWaiting()
  
      this.save$(data,"").subscribe({
        next: (res)=>{
          this.appConfig.onStopWaiting()
          AppUtilitie.openInfoDialog(this._dialog, AppFeeback.SAVE_SUCCESS, faCheckCircle)
          this._router.navigate([redirectTo], {replaceUrl:true})
        },
        error:(error)=>{
          this.appConfig.onStopWaiting()
          AppUtilitie.openInfoDialog(this._dialog, error)
        }
      })
  
    }
  
    get(){
      this.appConfig.onStartWaiting()
      
      this.find$("").subscribe({
        next: (res)=>{
          this.appConfig.onStopWaiting()
          this.testimonial$.next(res)
         
        },
        error:(error)=>{
          this.appConfig.onStopWaiting()
          AppUtilitie.openInfoDialog(this._dialog, error)
        }
      })
  
    }
  
    deleteItem(id:string){
      this.delete$(`${id}`).subscribe({
        next: (res)=>{
          this.appConfig.onStopWaiting()
          AppUtilitie.openInfoDialog(this._dialog, AppFeeback.DELETE_SUCCESS, faCheckCircle)
          this.get()
        },
        error:(error)=>{
          this.appConfig.onStopWaiting()
          AppUtilitie.openInfoDialog(this._dialog, error)
        }
      })
    }
  
    update(data:any ,uri:string, redirectTo:string){
      this.appConfig.onStartWaiting()
      this.patch$(data,uri).subscribe({
        next: (res)=>{
          this.appConfig.onStopWaiting()
          AppUtilitie.openInfoDialog(this._dialog, AppFeeback.UPDATE_SUCCESS, faCheckCircle)
          this._router.navigate([redirectTo], {replaceUrl:true})
        },
        error:(error)=>{
          this.appConfig.onStopWaiting()
          AppUtilitie.openInfoDialog(this._dialog, error)
        }
      })
    }
    
  }