<div>
    <div class="text-center">
        <h2> Notification </h2>
        <span> {{ message }} </span>
    </div>
    <div class="d-flex justify-content-end mt-4">
        <button (click)="confirm()" class="btn btn-success me-3">
            Oui
        </button>
        <button (click)="close()" class="btn btn-danger">
            Non
        </button>
    </div>
</div>