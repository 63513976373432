<section class="row py-3 mt-3 shadow-custom bg-white" style="border-radius: 17px;">

    <div id="htmlData" class="p-3">
    
      <nav class="d-flex justify-content-end mb-0 p-0" aria-label="Page navigation example">
        <ul class="pagination mb-0" *ngIf="testimonials.length > 0">
          <li class="page-item" *ngIf="hasPrevious">
            <a class="page-link" (click)="decrement()" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
              <span class="sr-only">Previous</span>
            </a>
          </li>
          <li class="page-item" [ngClass]="filter.page == 0 ? 'active'  : '' " (click)="paginate(0)">
            <a class="page-link">1</a>
          </li>
          <li *ngFor="let page of pages; index as i" class="page-item" (click)="paginate((i+1))" [ngClass]="filter.page == i+1 ? 'active'  : '' ">
            <a class="page-link">{{ i+2 }}</a>
          </li>
          <li class="page-item" *ngIf="hasNext">
            <a class="page-link" (click)="increment()" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
              <span class="sr-only">Next</span>
            </a>
          </li>
        </ul>
      </nav>

      <div class="table-responsive mt-1" >
        <table class="table table-hover">
            <thead>
                <tr>
                  <th scope="col " class="border-end">#</th>
                   <th scope="col" style="width:700px;">Url</th>
                   <th scope="col">Description</th>
                   <th scope="col" class="text-center">Action</th>
                </tr>
              </thead>
          <tbody >
                <tr *ngFor="let testimonial of testimonials; index as i">
                <th class="border-end">{{i+1}}</th>
                <td>{{testimonial.url}}</td>
                <td>{{testimonial.description ? testimonial.description: "-" }}</td>
               
                <td (click)="onClikedIn(i)"  class="align-middle text-center list-status-access {{showStatus[i] ? 'cliked': 'uncliked'}} d-inline-bloc position-relative " style="cursor:pointer;" >
                    <fa-icon  style="cursor:pointer; color:black !important;" [icon]="faEllipsis"></fa-icon>
                  
                    <div class="tooltip-bloc" style="z-index: 9999;">
                      <ul class="custom-tooltip p-2  bg-white tooltip-container text-white position-absolute">
                          <fa-icon [icon]="faCaretLeft" class="fa-2x caret text-white"></fa-icon>
    
                          <li class="d-flex mb-2" (click)="confirmDelete(testimonial._id)">
                           
                            <i class="{{'bi bi-trash3'}}"></i>
                            <span  class="ms-2">Supprimer</span>
                          </li>
    
                      </ul>
                    </div>
                  </td>
                </tr>
          </tbody>
    
        </table>
    
        <!-- 
        <div>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/UaRG0ea75xE?si=dXpm92YfJNrO-Lvl" frameborder="0" allowfullscreen></iframe>
        </div> 
        -->

      </div>
      </div>
    </section>
