import { Injectable } from "@angular/core"
import { MatDialog, MatDialogRef } from "@angular/material/dialog"
import { Subject } from "rxjs";
import { LoadingComponent } from "../components/dialogs/utilities/loading-component/loading-component.component";
import { validate, ValidationError } from "class-validator";
import { AppFeeback } from "../enums/app-feedback.enum";
import { AppUtilitie } from "../utilities/app-utilitie";

@Injectable({
    providedIn: 'root',
})

export class AppConfigService{

    userErrors:any={}
    
    private dialogRef: MatDialogRef<LoadingComponent> | undefined;
    public  waiting$: Subject<boolean> = new Subject<boolean>();
    public sendAction$:Subject<string>=new Subject<string>()

    constructor(
        private dialog: MatDialog,
    ){}

    onConnected(login: any): boolean{
        if(!login)
            return false;
        else{
            localStorage.setItem('email', JSON.stringify(login.email));
            localStorage.setItem('token', login.token);
            localStorage.setItem('accountId', login.accountId);
            localStorage.setItem('firstName', login.firstName);
            localStorage.setItem('lastName', login.lastName);
            return true;
        }
    }

    onDisconnect(){
        localStorage.clear();
    }

    sendAction(label:string){
      this.sendAction$.next(label)
    }

onLazyLoad(){
    this.waiting$.next(true);
}
onStopLazyLoad(){
    this.waiting$.next(false);
}

    onStartWaiting(message?: string){

        if(this.dialogRef){
            this.dialogRef.close()
        }
        this.dialogRef = this.dialog.open(LoadingComponent, {
            disableClose: true,
            data: {
                message: message
            }
        })
    }

    onStopWaiting(){
        if (this.dialogRef){
            this.waiting$.next(false);
            this.dialogRef.close()
        }
    }

    getItemFromlocalStorage(item: string){
        const response = localStorage.getItem(item);
        if(response)
            return response;

        return null;
    }

    public convertToClientTimeZone(date:string) {

        const formatdateCommand=date.replace(/([0-9]{2})\/([0-9]{2})\/([0-9]{2})/,'$2/$1/$3')+" +0000"
        const clientTZ=Intl.DateTimeFormat().resolvedOptions().timeZone
        return new Date(formatdateCommand).toLocaleString("fr-FR", {timeZone: clientTZ}); 
        
    }


    async validateOrReject(data:any) {
        try {
      
          await validate(data).then((errors: any) => {
            if (Array.isArray(errors) && errors.length > 0){
              console.log('missed ', errors)
              throw errors;
            }
            else{
                console.log("okkkkkkkkkkkk")
                const result:any=true
               return result;
            }
             
          });
      
        } catch (errors:any) {
      
          if(Array.isArray(errors)){
            this.userErrors = {}
            errors.map((error: ValidationError) => {
              this.userErrors[error.property] = error.constraints?.['isNotEmpty'] || error.constraints?.['isEmail']
            })
            return this.userErrors
          }else{
            AppUtilitie.openInfoDialog(this.dialog, AppFeeback.NETWORK_ERROR)
          }
        }
      }

}
