import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faEdit, faTrash, faEye, faEllipsisV, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { ConfirmDialogComponent } from 'src/app/components/dialogs/utilities/confirm-action-dialog/confirm-dialog.component';
import { AppConfigService } from 'src/app/services/app-config.service';
import { TestimonialService } from 'src/app/services/testimonials.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  faCaretLeft=faCaretRight
  faEllipsis=faEllipsisV;
  showStatus:boolean[]=[];
  
  constructor(
    public appConfig: AppConfigService,
    private dialog: MatDialog,
    private testimonialService: TestimonialService
  ) { }

  ngOnInit(): void {
    this.getTestimonials()
  }

  testimonials:any[]=[];

  hasContent:boolean=false;
  hasNext: boolean = false;
  hasPrevious: boolean = false;

  pages: Array<number> = new Array<number>();

  filter:any= {
    page: 0,
  }

  faEdit=faEdit;
  faTrash=faTrash
  faEye=faEye;

  getTestimonials(){
    this.testimonialService.get();
    this.testimonialService.testimonial$.subscribe((res:any)=>{
      this.testimonials=res.stories.reverse();
    })
  }

  paginate(page:number){
    this.filter.page=page;
  }

  increment(){
    if (this.testimonials) {
      this.filter.page = this.filter.page + 1;
      this.pages.length = this.filter.page;
    }
  }

  decrement(){
    if(this.filter.page > 0 && this.hasPrevious == true){
      this.filter.page = this.filter.page - 1;
      this.pages.length = this.filter.page;
     
    }
  }


  onClikedIn(index:number){
    console.log('status init', this.showStatus)
    this.showStatus[index]=!this.showStatus[index];

    this.showStatus.forEach((value:Boolean,key:number)=>{

       if( this.showStatus[index] == true ){
        if((index==key)){
          this.showStatus[key]=true;
        }
        else{
          this.showStatus[key]=false
        }

       }
})

  }

  delete(id:string){
    this.testimonialService.deleteItem(id)
  }

  confirmDelete(id:string){
    this.dialog.open(ConfirmDialogComponent, {
      width: "50%",
      data:{
        message:"Voulez-vous vraiment ce temoignage ?"
      }
    }).afterClosed().subscribe(res => {
      if(res == "YES"){
        this.delete(id)
      }
    })
   }


}
